<template>
  <div id="container">
    <el-carousel :interval="5000" arrow="always" height="256px">
      <el-carousel-item v-for="item in introTexts" :key="item">
        <div class="carousel-item">
          <h1>{{ item }}</h1>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div id="exhibition">
      <div id="exhibition-show">
        <h1>欢迎使用 西柚云调度系统——大禹</h1>
        <h3>本系统使用计算与存储分离的架构，让迁移变得更简单，有效提高服务器资源利用率</h3>
        <h3>随着机器数量的增加，用户使用时间冲突的概率越来越小，真正地实现“共享的价格，独享的体验”</h3>
      </div>
    </div>
    <div id="usage-step">
      <h2>简单几步即可使用我们的服务</h2>
      <el-steps :active="3" finish-status="success" align-center>
        <el-step title="创建实例" description="选择合适的镜像创建实例"></el-step>
        <el-step title="启动实例" description="我们倡导不使用时关闭实例，共建和谐社区"></el-step>
        <el-step title="使用" description="开始您的科研之旅"></el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  data() {
    return {
      introTexts: [
        "每个用户拥有独立root权限，万事不求人",
        "提供基础环境镜像，预装上千R包，开箱即用",
        "可以在不同服务器之间灵活调度，充分利用服务器资源",
        "分布式存储三副本数据备份，更可靠"
      ],
    };
  },
}
</script>

<style scoped lang="less">
.carousel-item {
  background: linear-gradient(to bottom, #409EFF, skyblue);
  color: white;
  width: 100%;
  height: 256px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#exhibition {
  background-image: url("../assets/hosting.png");
  padding: 280px 0 260px;
  height: 784px;
  z-index: 0;
  background-color: #0069ff;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;

  #exhibition-show {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

#usage-step {
  height: 512px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  padding: 8px;
  background-color: white;
}
</style>
