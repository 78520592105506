<template>
  <div id="container">
    <div id="domainCheck">
      <h2>线路域名检测</h2>
      <h4 style="color:grey;">自动检验线路连通性，如遇到某个线路不可用，请切换到其它线路使用</h4>
      <DomainCheck/>
      <div id="banIpListBoard">
        <h2>被封禁IP列表</h2>
        <h4 style="color:grey;margin-top: 8px">
          如出现多次SSH、Jupyter、Rstudio软件登录失败等行为，系统会自动对异常IP封禁，可以联系管理员解锁</h4>
        <div style="margin-top: 8px">
          <el-tag style="margin-left: 4px" v-for="ip in banIpList" :key="ip" class="mx-1" type="warning">{{
              ip
            }}
          </el-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DomainCheck from "@/components/DomainCheck";

export default {
  name: "StatusCheck",
  components: {DomainCheck},
  data() {
    return {
      banIpList: [],
    }
  },
  mounted() {
    this.getBanList();
  },
  methods: {
    getBanList() {
      this.$httpUtil.get('/api/v1/anonymous/get-ban-ip', {}).then(res => {
        if (res) {
          this.banIpList = res.data;
        }
      }, (res) => {
        console.log(res);
      });
    },
    refresh() {
      this.reload();
    },
  }
}
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

#container {
  display: flex;
  flex-direction: column;

  #domainCheck {
    width: 100%;
    .card-item();
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #banIpListBoard {
    display: flex;
    flex-direction: column;
    align-items: center;
    .card-item();
  }
}
</style>
